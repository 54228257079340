import { useMemo } from 'react';

import { MappedPartialAlbum } from '~/app/lib/mapper';

export const useResolveAlbums = ({
  albums,
  albumIds,
}: {
  albums: MappedPartialAlbum[] | undefined;
  albumIds: number[] | undefined;
}) => {
  return useMemo(() => {
    if (!albumIds?.length || !albums) {
      return;
    }

    // We want to remove duplicates by name, and we want to keep
    // the explicit version of the album if that information is provided.
    const albumsResolved = albumIds
      .map((albumId) => albums.find(({ id }) => id === albumId))
      .filter((item): item is MappedPartialAlbum => !!item);

    return albumsResolved.length ? albumsResolved : undefined;
  }, [albums, albumIds]);
};
